const Style = ({ attributes, clientId }) => {
	const { alignment, width, background, padding, border, shadow, headerMargin, titleTypo, titleColor, descTypo, descColor, headerSep, listIconSize, listIconColors, listTextTypo, listTextColor } = attributes;

	const iconSize = 30 < listIconSize ? listIconSize + listIconSize / 2 : listIconSize + listIconSize / 1.5;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${titleTypo?.googleFontLink ? `@import url(${titleTypo?.googleFontLink});` : ''}
		${descTypo?.googleFontLink ? `@import url(${descTypo?.googleFontLink});` : ''}
		${listTextTypo?.googleFontLink ? `@import url(${listTextTypo?.googleFontLink});` : ''}

		#ilbIconList-${clientId}{
			text-align: ${alignment};
		}
		#ilbIconList-${clientId} .ilbIconList{
			width: ${'0px' === width || '0%' === width || '0em' === width ? 'auto' : width};
			${background?.styles ?? 'background-color: #0000;'}
			padding: ${padding?.styles ?? '30px 25px'};
			${border?.styles ?? ''}
			box-shadow: ${shadow?.styles ?? '0 0 10px 0 #4527a480'};
		}
		#ilbIconList-${clientId} .ilbIconList .header{
			margin: ${headerMargin?.styles ?? '0 0 30px 0'};
		}
		#ilbIconList-${clientId} .ilbIconList .header .title{
			${titleTypo?.styles || 'font-size: 30px; font-weight: 700; text-transform: uppercase;'}
			color: ${titleColor};
		}
		#ilbIconList-${clientId} .ilbIconList .header .description{
			${descTypo?.styles || 'font-size: 18px; font-weight: 500;'}
			color: ${descColor};
		}
		#ilbIconList-${clientId} .ilbIconList .header .separator{
			${headerSep?.styles ?? 'width: 20%; border-top: 2px solid #828282;'}
		}
		#ilbIconList-${clientId} .ilbIconList ul.lists .icon{
			font-size: ${listIconSize}px;
			width: ${iconSize}px;
			height: ${iconSize}px;
			${listIconColors?.styles || 'color: #fff; background-color: #4527a4;'}
		}
		#ilbIconList-${clientId} .ilbIconList ul.lists .text{
			${listTextTypo?.styles || 'font-size: 15px;'}
			max-width: calc(100% - ${iconSize + 15}px);
			color: ${listTextColor};
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;