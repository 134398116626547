import { render } from '@wordpress/element';

import './style.scss';
import Style from './Style';
import IconList from './IconList';

// Business Card
document.addEventListener('DOMContentLoaded', () => {
	const allIconList = document.querySelectorAll('.wp-block-ilb-icon-list');
	allIconList.forEach(iconList => {
		const attributes = JSON.parse(iconList.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<IconList attributes={attributes} />
		</>, iconList);

		iconList?.removeAttribute('data-attributes');
	});
});