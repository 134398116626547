import { __ } from '@wordpress/i18n';
import { RichText } from '@wordpress/block-editor';

const IconList = ({ attributes, isBackend = false, wp, custom }) => {
	const { isTitle, title, isDesc, desc, lists, position, isHeaderSep } = attributes;

	return <div className={`ilbIconList ${position}`}>
		{isTitle || isDesc ? <div className='header'>
			{isBackend && isTitle ? <RichText className='title' tagName='h3' value={title} onChange={val => wp.setAttributes({ title: val })} placeholder={__('Title', 'icon-list')} inlineToolbar /> : isTitle && title && <RichText.Content className='title' tagName='h3' value={title} />}

			{isBackend && isDesc ? <RichText className='description' tagName='p' value={desc} onChange={val => wp.setAttributes({ desc: val })} placeholder={__('Description', 'icon-list')} inlineToolbar /> : isDesc && desc && <RichText.Content className='description' tagName='p' value={desc} />}

			{isHeaderSep && <span className='separator'></span>}
		</div> : null}

		<ul className='lists'>
			{lists?.length && lists.map((list, index) => {
				const { icon, text, link } = list;

				return <li key={index} className={`list ${isBackend && index === custom.activeIndex ? 'ilbNowEditing' : ''}`} onClick={() => isBackend && custom.setActiveIndex(index)}>
					{icon?.class && <i className={`icon ${icon?.class}`}></i>}

					{link && !isBackend ? <a className='link text' href={link}>{text}</a> : <p className='text'>{text}</p>}
				</li>
			})}
		</ul>
	</div>
}
export default IconList;